import { ImageTextBlockProps } from '@husky-x/gatsby-theme-husky/src/components/ImageTextBlock/models';
import { ImageTextBlock } from '@husky-x/gatsby-theme-husky/src/mappers/imageTextBlock/models';
import { pageBlockUIMapper } from '@husky-x/gatsby-theme-husky/src/mappers/pageBlock';

export const imageTextBlockMapper = ({
  pageBlockUI: [pageBlockUI],
  items,
  currentPageUid,
  background_color,
  html_class,
}: ImageTextBlock): ImageTextBlockProps => ({
  pageBlock: pageBlockUIMapper(pageBlockUI.ui),
  items: items.map((item) =>
    Object.entries(item)
      .filter(([, value]) => Boolean(value))
      .reduce(
        (result, [key, { typeName, ...rest }]) => ({
          ...result,
          [key]: { typeName, item: rest },
        }),
        {}
      )
  ),
  currentPageUid,
  background_color,
  html_class,
});
