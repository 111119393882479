import React, { FC } from 'react';
import { BodyRenderProps } from '@husky-x/gatsby-theme-husky/src/components/BodyRender/models';
import { contactUsBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/contactUsBlock';
import { iframeMapper } from '@husky-x/gatsby-theme-husky/src/mappers/iframe';
import { Image } from '@husky-x/gatsby-theme-husky/src/mappers/image/models';
import { imageBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/imageBlock';
import { imagePlusTextBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/imagePlusTextBlock';
import { itemListingMapper } from '@husky-x/gatsby-theme-husky/src/mappers/itemListing';
import { ldsBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/ldsBlock';
import { newsletterMapper } from '@husky-x/gatsby-theme-husky/src/mappers/newsletter';
import { promoFormMapper } from '@husky-x/gatsby-theme-husky/src/mappers/promoForm';
import { retailersBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/retailers';
import { salsifyColumnsTextMapper } from '@husky-x/gatsby-theme-husky/src/mappers/salsifyColumnsText';
import { salsifyTextMapper } from '@husky-x/gatsby-theme-husky/src/mappers/salsifyText';
import { searchResultsMapper } from '@husky-x/gatsby-theme-husky/src/mappers/searchResults';
import { shoppingCartMapper } from '@husky-x/gatsby-theme-husky/src/mappers/shoppingCart';
import { sitemapMapper } from '@husky-x/gatsby-theme-husky/src/mappers/sitemap';
import { teaserMapper } from '@husky-x/gatsby-theme-husky/src/mappers/teaser';
import { typographyMapper } from '@husky-x/gatsby-theme-husky/src/mappers/typography';
import { BazaarVoiceReviews } from '@phx-husky/bazaarvoice-components';

import { imageTextBlockMapper } from '~/mappers/imageTextBlock/imageTextBlockMapper';

const SalsifyColumnsText = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/SalsifyColumnsText')
);
const ItemListing = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/ItemListing')
);
const Teaser = React.lazy(() =>
  import('@design-system/teaser').then((m) => ({ default: m.Teaser }))
);
const ContactUs = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/ContactUsBlock')
);
const PageBlock = React.lazy(() =>
  import('@design-system/page-block').then((m) => ({ default: m.PageBlock }))
);
const ImageTextBlock = React.lazy(() => import('~/components/ImageTextBlock/ImageTextBlock'));
const Typography = React.lazy(() =>
  import('@design-system/typography').then((m) => ({ default: m.Typography }))
);
const ImageBlock = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/ImageBlock')
);
const ImagePlusTextBlock = React.lazy(() =>
  import('@design-system/image-plus-text').then((m) => ({
    default: m.ImagePlusText,
  }))
);
const LdsBlock = React.lazy(() => import('@husky-x/gatsby-theme-husky/src/components/LdsBlock'));
const DebugComponent = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/BodyRender/DebugComponent')
);
const SitemapList = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/SitemapList')
);
const Iframe = React.lazy(() => import('@husky-x/gatsby-theme-husky/src/components/Iframe'));
const RetailersPageBlock = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/RetailersPageBlock')
);
const SearchResults = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/SearchResults')
);
const NewsletterForm = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/NewsletterForm')
);
const ShoppingCart = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/ShoppingCart')
);
const PromoForm = React.lazy(() => import('@husky-x/gatsby-theme-husky/src/components/PromoForm'));
const LoginRegisterForm = React.lazy(
  () => import('@husky-x/gatsby-theme-husky/src/components/LoginRegisterForm')
);

export const typesMapper = {
  default: (typeName) => [
    (item) =>
      process.env.NODE_ENV === 'development' ? (
        <DebugComponent typeName={typeName} {...item} />
      ) : null,
    (item) => item,
  ],
  ItemListing: [ItemListing, itemListingMapper],
  Teaser: [Teaser, teaserMapper],
  ContactUs: [ContactUs, contactUsBlockMapper],
  ImageTextBlock: [
    ImageTextBlock,
    imageTextBlockMapper,
    ({ items, pageBlockUI, background_color, html_class }) => ({
      items,
      pageBlockUI,
      background_color,
      html_class,
    }),
  ],
  TextBlock: [Typography, ({ typography }) => typographyMapper(typography)],
  Typography: [Typography, typographyMapper],
  Image: [ImageBlock, (image: Image) => imageBlockMapper({ images: [image] })],
  SalsifyText: [PageBlock, salsifyTextMapper],
  SalsifyColumnsText: [SalsifyColumnsText, salsifyColumnsTextMapper],
  ImageBlock: [ImageBlock, imageBlockMapper],
  ImagePlusTextBlock: [ImagePlusTextBlock, imagePlusTextBlockMapper],
  LdsBlock: [LdsBlock, ldsBlockMapper],
  SitemapBlock: [SitemapList, sitemapMapper],
  Iframe: [Iframe, iframeMapper],
  RetailersBlock: [RetailersPageBlock, retailersBlockMapper],
  SearchResults: [SearchResults, searchResultsMapper],
  Newsletter: [NewsletterForm, newsletterMapper],
  PromoForm: [PromoForm, promoFormMapper],
  ShoppingCart: [ShoppingCart, shoppingCartMapper],
  BazaarVoiceReviews: [
    BazaarVoiceReviews,
    (items) => items,
    (customValue) => ({
      productId: customValue.productId,
    }),
  ],
  LoginRegister: [LoginRegisterForm],
};

const BodyRender: FC<BodyRenderProps> = ({ items, currentPageUid, externalData }) => {
  const renderItems = items.map((item) => {
    const value = Object.values(item).find((val) => val);

    return value || { typeName: 'Unknown component', item: [] };
  });

  return (
    <>
      {renderItems.map((allValues, index) => {
        const { typeName, item } = allValues;
        const [ItemComponent, itemMapper, customValue] =
          typesMapper[typeName] || typesMapper.default(typeName);

        if (!ItemComponent) {
          console.error(`Missing component for type: ${typeName}`);
        }

        const key = `${typeName}__${index}`;
        let value = Array.isArray(item) ? item[0] : item;

        if (customValue) {
          value = customValue(allValues);
        }

        return ItemComponent ? (
          <ItemComponent
            key={key}
            {...(itemMapper ? itemMapper(value, currentPageUid, externalData) : value)}
          />
        ) : null;
      })}
    </>
  );
};

export default BodyRender;
